import PropTypes from 'prop-types'
import AppMetaContext, { AppMetaContextProvider } from './AppMeta'
import UserContext, { UserContextProvider } from './User'
import FarmContext, { FarmContextProvider } from './Farm'
import { SnackContextProvider } from './SnackQueue'
import BreadcrumbsContext, { BreadcrumbContextProvider } from './Breadcrumb'

export const Contexts = ({ children }) => {
  return (
    <AppMetaContextProvider>
      <SnackContextProvider>
        <UserContextProvider>
          <BreadcrumbContextProvider>
            <FarmContextProvider>{children}</FarmContextProvider>
          </BreadcrumbContextProvider>
        </UserContextProvider>
      </SnackContextProvider>
    </AppMetaContextProvider>
  )
}
Contexts.propTypes = {
  children: PropTypes.node.isRequired,
}

export const AppMeta = AppMetaContext
export const User = UserContext
export const Farm = FarmContext
export const Breadcrumbs = BreadcrumbsContext
