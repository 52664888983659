import { useRouter } from 'next/router'
import { format } from 'date-fns'

export const useDateOnParam = () => {
  const { isReady, push, query } = useRouter()

  const onDateOnParamChange = ({ dateOn }) =>
    push(
      { query: { ...query, dateOn: format(dateOn, 'yyyy-MM-dd') } },
      undefined,
      { shallow: true },
    )

  const loading = !isReady
  const dateOnParam = query?.dateOn

  return { loading, dateOnParam, onDateOnParamChange }
}
