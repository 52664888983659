import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import DialogSpinner from 'components/DialogSpinner'
import { useDialog, useDialogState } from 'hooks'

export const ConfirmDialog = ({
  title,
  children,
  open,
  setOpen,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          No
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setOpen(false)
            onConfirm()
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export const ConfirmationDialog = ({
  children,
  isSubmitting,
  onConfirmed,
  onReject,
  open,
  title,
}) => (
  <Dialog open={open} onClose={onReject} aria-labelledby="confirm-dialog">
    <DialogSpinner spin={isSubmitting} />
    <DialogTitle id="confirm-dialog">{title}</DialogTitle>
    <DialogContent dividers>{children}</DialogContent>
    <DialogActions>
      <Button color="secondary" onClick={onReject}>
        No
      </Button>
      <Button color="primary" variant="outlined" onClick={onConfirmed}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
)

ConfirmationDialog.propTypes = {
  children: PropTypes.node.isRequired,
  isSubmitting: PropTypes.func,
  onConfirmed: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

export const useConfirmation = ({ onConfirmation, onRejection }) => {
  const [dialogState, onDialogState] = useDialogState()
  const { open, onOpen, onClose } = useDialog(onDialogState)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onConfirm = (...props) => onOpen(...props)

  const onConfirmed = async () => {
    setIsSubmitting(true)
    await onConfirmation(dialogState)
    setIsSubmitting(false)
    onClose()
  }

  const onReject = () => onClose()

  return {
    isSubmitting,
    onConfirm,
    onConfirmed,
    onReject,
    open,
    ...dialogState,
  }
}
