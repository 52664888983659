import { Autocomplete, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { CurrencyCode } from 'types'

export const CurrencyCodeDropDown = ({
  currencyCodes = Object.keys(CurrencyCode),
  currencyCode = null,
  onChange,
  ...props
}) => (
  <Autocomplete
    options={currencyCodes}
    value={currencyCode}
    onChange={(_, code) => onChange({ code })}
    renderInput={(params) => <TextField label="Currency Code" {...params} />}
    {...props}
  />
)
CurrencyCodeDropDown.propTypes = {
  currencyCodes: PropTypes.array,
  currencyCode: PropTypes.object,
  onChange: PropTypes.func,
}
