import { createTheme } from '@material-ui/core/styles'

export const palette = {
  common: { black: '#1E2229', white: '#fefefe' },
  background: { paper: '#fefefe', default: 'rgb(234, 236, 238)' },
  primary: {
    light: '#54ca98',
    main: '#0e986a',
    dark: '#00693f',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ff9c66',
    main: '#ff6a39',
    dark: '#c53809',
    contrastText: '#fff',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  border: {
    light: 'rgba(145, 158, 171, 0.42)',
    main: 'rgba(145, 158, 171, 0.24)',
    dark: 'rgba(145, 158, 171, 0.12))',
  },
  text: {
    primary: 'rgba(30, 34, 41, 0.87)',
    secondary: 'rgba(30, 34, 41, 0.54)',
    disabled: 'rgba(30, 34, 41, 0.38)',
    hint: 'rgba(30, 34, 41, 0.38)',
  },
}

export const components = {
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        overflow: 'hidden',
        boxShadow:
          '0 1px 1px rgba(30,34,41,0.12), 0 2px 2px rgba(30,34,41,0.12)',
        '& .MuiPaper-elevation0': {
          boxShadow: 'none',
          border: '1px solid ' + `${palette.border.light}`,
        },
        '@media print': {
          borderRadius: 2,
          boxShadow: 'none',
          border: '1px solid ' + `${palette.border.main}`,
          '& .MuiPaper-elevation0': {
            boxShadow: 'none',
            border: '1px solid ' + `${palette.border.light}`,
          },
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        marginRight: 20,
      },
      paper: {
        borderRadius: 8,
        left: '20px',
        marginTop: '20px',
        height: '96%',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        paddingTop: 18,
        backgroundColor: `rgba(234, 236, 238,0.6)`,
        backdropFilter: 'blur(6px)',
        color: palette.common.black,
        boxShadow: 'none',
        border: 0,
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        marginBottom: 30,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minHeight: 'auto',
        flexDirection: 'row',
        '& svg': {
          marginRight: 15,
          marginBottom: 0,
          marginTop: 5,
        },
        '&.MuiButtonBase-root:first-child': {
          // paddingLeft: 0,
        },
        '&.MuiButtonBase-root': {
          textTransform: 'none',
          letterSpacing: 0,
          fontSize: '1rem',
          color: palette.common.black,
        },

        '&.MuiButtonBase-root:hover': {
          color: palette.primary.main,
          opacity: 1,
        },
        '&.Mui-selected': {
          color: palette.common.black,
        },
        '&.Mui-selected:hover': {},
      },
    },
  },
  // MuiTab: {
  //   styleOverrides: {
  //     root: {
  //       '&.MuiButtonBase-root:hover': {
  //         color: palette.primary.main,
  //         opacity: 1,
  //       },
  //       '&.Mui-selected': {
  //         backgroundColor: palette.primary.main,
  //         color: palette.common.white,
  //       },
  //       '&.Mui-selected:hover': {
  //         backgroundColor: palette.primary.dark,
  //         color: palette.common.white,
  //       },
  //     },
  //   },
  // },

  // MuiTableCell: {
  //   styleOverrides: {
  //     root: {
  //       color: palette.primary.main,
  //     },
  //   },
  // },
  MuiTableRow: {
    styleOverrides: {
      root: {
        fontSize: '0.875rem',
        '&:last-child > th': {
          border: 0,
          borderBottom: 0,
          borderBottomWidth: 0,
        },
        '&:last-child > td': {
          borderTop: '1px solid ' + `${palette.border.main}`,
        },
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        backgroundColor: palette.common.white,
        padding: '7px 15px 6px', // to match textfield height
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        // backgroundColor: palette.common.white,
        // color: palette.primary.main,
        '&:hover': {
          backgroundColor: palette.primary.light + '14',
          color: palette.primary.main,
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        backgroundColor: palette.common.white,
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
          {
            outline: 'none',
          },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        color: palette.text.hint,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        paddingTop: 20,
        backgroundColor: palette.background.default,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: palette.common.white,
      },
    },
  },
  tableCategoryHeader: {
    borderBottom: '1px solid ' + `${palette.border.main}`,
  },
}

export const drawers = {
  right: {
    width: '330px',
  },
  left: {
    width: '240px',
  },
}

export const typography = {
  tag: {
    fontSize: 12,
    padding: '2px 4px',
    borderRadius: 3,
    fontWeight: 600,
  },
  hint: {
    fontSize: 14,
    color: palette.text.hint,
    display: 'block',
  },
  link: {
    color: palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumb: {
    fontSize: '1.1rem',
  },
  small: {
    fontSize: 14,
    display: 'block',
  },
  subtitle1: {
    color: '#888',
    fontSize: 14,
  },
}

// Create a theme instance.
const theme = createTheme({
  palette,
  drawers,
  components,
  typography,
})

export default theme
