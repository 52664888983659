export const ALL = 'all'
export const AMD = 'amd'
export const AZN = 'azn'
export const BAM = 'bam'
export const BGN = 'bgn'
export const CHF = 'chf'
export const CZK = 'czk'
export const DKK = 'dkk'
export const EUR = 'eur'
export const GBP = 'gbp'
export const GEL = 'gel'
export const HRK = 'hrk'
export const HUF = 'huf'
export const ISK = 'isk'
export const MDL = 'mdl'
export const MKD = 'mkd'
export const NOK = 'nok'
export const PLN = 'pln'
export const RON = 'ron'
export const RSD = 'rsd'
export const RUB = 'rub'
export const SEK = 'sek'
export const TRY = 'try'
export const UAH = 'uah'
export const USD = 'usd'
