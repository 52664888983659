import { gql, useQuery } from '@apollo/client'
import { CORE_FARM_FIELDS } from 'components/queries'

const GET_FARM = gql`
  ${CORE_FARM_FIELDS}
  query GetFarmForContext($id: ID!) {
    getFarm(id: $id) {
      ...CoreFarmFields

      roles
      config

      configs {
        id
        key
        value
        required
      }
    }
  }
`

export const useFarm = ({ farmId }) => {
  const { data, ...rest } = useQuery(GET_FARM, {
    variables: { id: farmId },
    skip: !farmId || farmId === 'undefined',
  })

  return { farm: data?.getFarm, ...rest }
}

export const ADD_FARM = gql`
  ${CORE_FARM_FIELDS}
  mutation AddFarm(
    $name: String!
    $meta: JSON!
    $timezone: String!
    $locale: String!
    $motherFarmId: ID
  ) {
    addFarm(
      name: $name
      meta: $meta
      timezone: $timezone
      locale: $locale
      motherFarmId: $motherFarmId
    ) {
      ...CoreFarmFields
    }
  }
`

export const EDIT_FARM = gql`
  ${CORE_FARM_FIELDS}
  mutation EditFarm(
    $id: ID!
    $name: String!
    $meta: JSON!
    $timezone: String!
    $locale: String!
    $motherFarmId: ID
  ) {
    editFarm(
      id: $id
      name: $name
      meta: $meta
      timezone: $timezone
      locale: $locale
      motherFarmId: $motherFarmId
    ) {
      ...CoreFarmFields
      id
    }
  }
`

export const GET_FARMS = gql`
  ${CORE_FARM_FIELDS}
  query GetFarms {
    getFarms {
      ...CoreFarmFields
    }
  }
`
