import PropTypes from 'prop-types'
import { ApolloProvider } from '@apollo/client'

import { useApollo } from '../../api/client'

export const Apollo = ({ initialApolloState, children }) => {
  const apolloClient = useApollo(initialApolloState)

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

Apollo.propTypes = {
  initialApolloState: PropTypes.object,
  children: PropTypes.node.isRequired,
}
