import {
  format as formatDate,
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import locale from '../src/locale'

export const getNewestCreatedAt = (arr, format = 'Pp') => {
  if (!arr || !arr?.length) {
    return 'never'
  }
  const sorted = [...arr].sort((a, b) =>
    parseInt(a.createdAt) < parseInt(b.createdAt) ? 1 : -1,
  )
  return localDatetime(sorted[0].createdAt, format)
}

export const localDatetime = (
  date,
  format = 'P',
  timezone = 'Europe/Copenhagen',
) => {
  date = parseDate(date)
  if (!date) {
    return ''
  } else {
    date = utcToZonedTime(date, timezone)
    return formatDate(date, format, { locale })
  }
}

export const parseDate = (date) => {
  let parsed = null
  if (date instanceof Date && !isNaN(date.getTime?.())) {
    return date
  }
  if (typeof date === 'string' && /^\d+$/.test(date)) {
    date = parseInt(date)
  }
  if (['number', 'string'].includes(typeof date)) {
    parsed = new Date(date)
  }
  const isInvalid = isNaN(parsed?.getTime?.())
  return isInvalid ? null : parsed
}

// { value: 13, unit: "DAYS" } => { days: 13 }
export const durationValueDateFnsAdaper = ({ value, unit }) => {
  const unitDict = {
    DAYS: 'days',
    DAY: 'days',
    MINUTE: 'minutes',
    MINUTES: 'minutes',
    HOUR: 'hours',
    HOURS: 'hours',
    WEEK: 'weeks',
    WEEKS: 'weeks',
    YEAR: 'years',
    YEARS: 'years',
    SECOND: 'seconds',
    SECONDS: 'seconds',
    MONTH: 'months',
    MONTHS: 'months',
  }

  if (!Object.keys(unitDict).includes(unit)) {
    throw new Error(`Unable to process invalid duration unit '${unit}'`)
  }

  if (typeof value !== 'number') {
    value = parseInt(value)
  }

  if (isNaN(value)) {
    throw new Error(`Unable to process invalid duration value '${value}'`)
  }

  return { [unitDict[unit]]: value }
}

export const XGridColDateRender =
  (key, format = 'P HH:mm', fallback = undefined) =>
  (params) => {
    try {
      let dateParsable = params.row[key]
      if (dateParsable instanceof Date && !isNaN(dateParsable.getTime())) {
        dateParsable = dateParsable.toISOString()
      } else if (typeof dateParsable !== 'string') {
        return fallback
      } else if (/^\d+$/.test(dateParsable)) {
        dateParsable = parseInt(dateParsable)
      }

      const d = new Date(dateParsable)

      // format() throws if invalid date
      const validDate = !isNaN(d.getTime())
      return validDate ? formatDate(d, format, { locale }) : fallback
    } catch (e) {
      return fallback
    }
  }

export const timeLeft = (
  startDate,
  endDate = new Date(),
  roundingMethod = Math.round,
) => {
  startDate = parseDate(startDate)

  if (!startDate) {
    return 'never'
  }

  const daysLeft = differenceInCalendarDays(startDate, endDate)
  const yearsLeft = differenceInCalendarYears(startDate, endDate)
  const weeksLeft = differenceInCalendarWeeks(startDate, endDate, {
    weekStartsOn: 1,
  })

  const timeLeft = {
    year: yearsLeft,
    week: weeksLeft,
    day: daysLeft,
  }

  let unitChoice = 'week'

  if (daysLeft < 7) {
    unitChoice = 'day'
  } else if (weeksLeft > 104) {
    unitChoice = 'year'
  }

  const moreThanOne = timeLeft[unitChoice] > 1
  const rounded = roundingMethod(timeLeft[unitChoice])
  return `${rounded} ${unitChoice}${moreThanOne ? 's' : ''}`
}

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

export function assignDeep(target, source) {
  const output = Object.assign({}, target)

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = assignDeep(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }

  return output
}
