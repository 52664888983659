import PropTypes from 'prop-types'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import locale from '../../src/locale'

export const Localization = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
      {children}
    </LocalizationProvider>
  )
}

Localization.propTypes = {
  children: PropTypes.node.isRequired,
}
