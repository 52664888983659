export const toMainUnits = (amount, currency) => {
  const inMainUnits = (amount / currency?.divisor).toFixed(
    Math.log10(currency?.divisor),
  )
  return Number.isFinite(Number(inMainUnits)) ? inMainUnits : undefined
}

export const toLocaleMainUnits = (amount, currency) =>
  amount && currency
    ? (amount / currency.divisor).toLocaleString(undefined, {
        style: 'currency',
        currency: currency.code,
        currencyDisplay: 'narrowSymbol',
      })
    : undefined

export const toSubUnits = (amount, currency) => {
  const inSubunits = Math.trunc(amount * currency?.divisor)
  return Number.isFinite(inSubunits) ? inSubunits : undefined
}
