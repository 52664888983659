import PropTypes from 'prop-types'

import { StylesCache } from './StylesCache'
import { Theme } from './Theme'
import { Apollo } from './Apollo'
import { Localization } from './Localization'
import { Contexts } from './Contexts'

export const Providers = ({ initialApolloState, children }) => {
  return (
    <StylesCache>
      <Theme>
        <Apollo initialApolloState={initialApolloState}>
          <Localization>
            <Contexts>{children}</Contexts>
          </Localization>
        </Apollo>
      </Theme>
    </StylesCache>
  )
}

Providers.propTypes = {
  initialApolloState: PropTypes.object,
  children: PropTypes.node.isRequired,
}
