import { IconButton, Tooltip } from '@material-ui/core'
import {
  AddCircleOutlineOutlined,
  ArchiveOutlined,
  CalendarTodayOutlined,
  DeleteOutline,
  DeleteSweepOutlined,
  DriveFileRenameOutline,
  EditOutlined,
  LeaderboardOutlined,
  ShuffleOutlined,
  ImportExport,
} from '@material-ui/icons'
import PropTypes from 'prop-types'

const propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export const CalendarIconButton = ({
  title = 'Toggle calendar',
  onClick,
  ...props
}) => (
  <Tooltip title={title}>
    <IconButton size="small" onClick={onClick} {...props}>
      <CalendarTodayOutlined />
    </IconButton>
  </Tooltip>
)
CalendarIconButton.propTypes = propTypes

export const DeleteIconButton = ({ title = 'Delete', onClick, ...props }) => (
  <Tooltip title={title}>
    <IconButton size="small" onClick={onClick} {...props}>
      <DeleteOutline />
    </IconButton>
  </Tooltip>
)
DeleteIconButton.propTypes = propTypes

export const DeleteMultiIconButton = ({
  title = 'Delete',
  onClick,
  ...props
}) => (
  <Tooltip title={title}>
    <IconButton size="small" onClick={onClick} {...props}>
      <DeleteSweepOutlined />
    </IconButton>
  </Tooltip>
)
DeleteMultiIconButton.propTypes = propTypes

export const EditIconButton = ({ title = 'Edit', onClick, ...props }) => (
  <Tooltip title={title}>
    <IconButton size="small" onClick={onClick} {...props}>
      <EditOutlined />
    </IconButton>
  </Tooltip>
)
EditIconButton.propTypes = propTypes

export const AdjustIconButton = ({ title = 'Adjust', onClick, ...props }) => (
  <Tooltip title={title}>
    <IconButton size="small" onClick={onClick} {...props}>
      <ImportExport />
    </IconButton>
  </Tooltip>
)
AdjustIconButton.propTypes = propTypes

export const AdjustShippingPackageIconButton = ({
  title = 'Adjust boxes',
  onClick,
  ...props
}) => (
  <Tooltip title={title}>
    <IconButton size="small" onClick={onClick} {...props}>
      <ArchiveOutlined />
    </IconButton>
  </Tooltip>
)
AdjustShippingPackageIconButton.propTypes = propTypes

export const TransferIconButton = ({
  title = 'Trasfer',
  onClick,
  ...props
}) => (
  <Tooltip title={title}>
    <IconButton size="small" onClick={onClick} {...props}>
      <ShuffleOutlined />
    </IconButton>
  </Tooltip>
)
TransferIconButton.propTypes = propTypes

export const AddIconButton = ({ title = 'Add new', onClick, ...props }) => (
  <Tooltip title={title}>
    <IconButton size="small" onClick={onClick} {...props}>
      <AddCircleOutlineOutlined />
    </IconButton>
  </Tooltip>
)
AddIconButton.propTypes = propTypes

export const ChartIconButton = ({
  title = 'Toggle chart',
  onClick,
  ...props
}) => (
  <Tooltip title={title}>
    <IconButton size="small" onClick={onClick} {...props}>
      <LeaderboardOutlined />
    </IconButton>
  </Tooltip>
)
ChartIconButton.propTypes = propTypes

export const DeleteMultipleButton = ({
  title = 'Delete multiple',
  onClick,
  ...props
}) => (
  <Tooltip title={title}>
    <IconButton size="small" onClick={onClick} {...props}>
      <DeleteSweepOutlined />
    </IconButton>
  </Tooltip>
)
DeleteMultipleButton.propTypes = propTypes

export const EditMultipleButton = ({
  title = 'Edit multiple',
  onClick,
  ...props
}) => (
  <Tooltip title={title}>
    <IconButton size="small" onClick={onClick} {...props}>
      <DriveFileRenameOutline />
    </IconButton>
  </Tooltip>
)
EditMultipleButton.propTypes = propTypes
