import { InputAdornment, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { handleFieldChange } from './helpers'

export const TraysField = ({ trays, trayType, onChange, ...props }) => (
  <TextField
    fullWidth
    name="trays"
    label="Trays"
    type="number"
    InputProps={{
      ...(trayType && {
        endAdornment: (
          <InputAdornment position="end">{trayType.name}(s)</InputAdornment>
        ),
      }),
    }}
    inputProps={{ min: 0, step: 0.2 }}
    value={trays || ''}
    onChange={handleFieldChange(onChange)}
    {...props}
  />
)
TraysField.propTypes = {
  trays: PropTypes.number,
  trayType: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}
