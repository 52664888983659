import { TextField, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { handleFieldChange } from './helpers'

export const RecurringInterval = {
  WEEKLY: 7,
  FORTNIGHTLY: 14,
  EVERY_THIRD: 21,
  MONTHLY: 28,
}

export const RecurringIntervalSelect = ({ interval, onChange, ...props }) => (
  <TextField
    select
    label="Week interval"
    name="interval"
    value={interval}
    onChange={handleFieldChange(onChange)}
    {...props}
  >
    <MenuItem key={RecurringInterval.WEEKLY} value={RecurringInterval.WEEKLY}>
      1 Week
    </MenuItem>
    <MenuItem
      key={RecurringInterval.FORTNIGHTLY}
      value={RecurringInterval.FORTNIGHTLY}
    >
      2 Weeks
    </MenuItem>
    <MenuItem
      key={RecurringInterval.EVERY_THIRD}
      value={RecurringInterval.EVERY_THIRD}
    >
      3 Weeks
    </MenuItem>
    <MenuItem key={RecurringInterval.MONTHLY} value={RecurringInterval.MONTHLY}>
      4 Weeks
    </MenuItem>
  </TextField>
)

RecurringIntervalSelect.propTypes = {
  interval: PropTypes.oneOf(Object.values(RecurringInterval)),
  onChange: PropTypes.func.isRequired,
}

export const useRecurringInterval = () => {
  const [interval, setInterval] = useState(RecurringInterval.WEEKLY)

  const onIntervalChange = ({ interval }) => setInterval(interval)

  return { interval, onIntervalChange }
}
