import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { handleIntFieldChange } from './helpers'

export const CountField = ({ count, onChange, ...props }) => (
  <TextField
    label="Count"
    type="number"
    name="count"
    value={count || ''}
    onChange={handleIntFieldChange(onChange)}
    {...props}
  />
)

CountField.propTypes = {
  count: PropTypes.string,
  onChange: PropTypes.func,
}
