import { Avatar } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 'bold',
  },
}))

export function FarmAvatar({ farm }) {
  const theme = useTheme()
  const classes = useStyles()
  const initials = useMemo(() => {
    if (!farm?.name) return ''
    return farm.meta?.initials || farm.name[0]
  }, [farm])

  return (
    <Avatar
      className={classes.avatar}
      variant="rounded"
      style={{ backgroundColor: farm?.meta?.color || theme.palette.primary }}
    >
      {initials}
    </Avatar>
  )
}

FarmAvatar.propTypes = {
  farm: PropTypes.object,
}
