export const AUTOCOMPLETE_TEMPLATE_PARTIALS = 'autocomplete-template-partials'
export const BATCH_TASKS_BOXES = 'batch-tasks-boxes'
export const FARM_TASK_BOXES = 'farm-task-boxes'
export const SUPPLY_CUSTOMER = 'supply-customer'
export const BATCH_RECURRING_WINDOW = 'batch-recurring-window'
export const BATCH_SINGLE_WINDOW = 'batch-single-window'
export const METABASE_DASHBOARDS = 'metabase-dashboards'
export const HUB_URL = 'hub-url'
export const SURPLUS_MINIMUM_QUANTITY = 'surplus-minimum-quantity'
export const DEFAULT_CURRENCY_ID = 'default-currency-id'
