import Head from 'next/head'
import { useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'

import 'react-timelines/lib/css/style.css'
import '../src/timeline.css'
import '../src/hide-mui-watermark.css'

import { Providers } from '../components/Providers'
import RequireLogin from '../components/RequireLogin'

export default function MyApp({ Component, pageProps }) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <Fragment>
      <Head>
        <title>NaboOS</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>

      <Providers {...pageProps}>
        <RequireLogin excludePaths={['/user/recovery/[code]']}>
          <Component {...pageProps} />
        </RequireLogin>
      </Providers>
    </Fragment>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
}
