import { useRouter } from 'next/router'

export const useCustomerIdParam = () => {
  const {
    isReady,
    query: { customerId, ...restQuery },
    push,
  } = useRouter()

  const onCustomerIdParamChange = ({ customerId }) =>
    push(
      { query: { ...restQuery, ...(customerId && { customerId }) } },
      undefined,
      { shallow: true },
    )

  const loading = !isReady

  return { loading, customerId, onCustomerIdParamChange }
}
