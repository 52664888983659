import { Autocomplete, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'

export const CurrencyDropDown = ({
  currencies = [],
  currency = null,
  onChange,
  required,
  ...props
}) => (
  <Autocomplete
    options={currencies}
    value={currency}
    getOptionLabel={(c) => `${c.code} (${c.symbol})`}
    onChange={(_, currency) => onChange({ currency })}
    renderInput={(params) => (
      <TextField required={required} label="Currency" {...props} {...params} />
    )}
    {...props}
  />
)
CurrencyDropDown.propTypes = {
  currencies: PropTypes.array,
  currency: PropTypes.object,
  onChange: PropTypes.func,
  required: PropTypes.bool,
}

export const useCurrency = () => {
  const [currency, setCurrency] = useState()

  const onCurrencyChange = ({ currency } = {}) => setCurrency(currency)

  return { currency, onCurrencyChange }
}
