import { useMemo, useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_FARMS } from '../queries'
import { FarmListComponent } from './Component'
import PropTypes from 'prop-types'

export function FarmList({ onFarmClick }) {
  const { data, loading } = useQuery(GET_FARMS)
  const [editingFarm, setEditingFarm] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [forceAddNew, setForceAddNew] = useState(false)

  const farms = useMemo(() => {
    if (Array.isArray(data?.getFarms)) {
      return data.getFarms
    } else {
      return []
    }
  }, [data])

  const onEditFarmClick = useCallback((farm) => {
    setEditingFarm(farm)
    setForceAddNew(false)
    setOpenDialog(true)
  }, [])

  const openPrefilledDialog = useCallback((farm) => {
    setEditingFarm(farm)
    setForceAddNew(true)
    setOpenDialog(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpenDialog(false)
    setEditingFarm()
    setForceAddNew(false)
  }, [])

  const onFabAddButtonClick = useCallback(() => {
    setOpenDialog(true)
    setEditingFarm()
    setForceAddNew(false)
  }, [])

  return (
    <FarmListComponent
      editingFarm={editingFarm}
      farms={farms || []}
      forceAddNew={forceAddNew}
      handleClose={handleClose}
      loading={loading}
      onFarmClick={onFarmClick}
      onEditFarmClick={onEditFarmClick}
      onFabAddButtonClick={onFabAddButtonClick}
      openDialog={openDialog}
      openPrefilledDialog={openPrefilledDialog}
    />
  )
}

FarmList.propTypes = {
  onFarmClick: PropTypes.func,
}
