import { Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/styles'
import { clsx } from 'clsx'

export const AddFab = ({ classes, ...props }) => {
  const { fab } = useStyles()

  return (
    <Fab color="primary" className={clsx(fab, classes)} {...props}>
      <AddIcon />
    </Fab>
  )
}

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 6,
  },
}))
