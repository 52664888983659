import { makeStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  dialogSpinner: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: '#fffa',
    zIndex: '1',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default function DialogSpinner({ spin }) {
  const classes = useStyles()

  return spin ? (
    <div className={classes.dialogSpinner}>
      <CircularProgress />
    </div>
  ) : (
    ''
  )
}

DialogSpinner.propTypes = {
  spin: PropTypes.bool.isRequired,
}
