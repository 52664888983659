import pkg from '../../../package.json'
import { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

const { name, version } = pkg

const GET_APP_INFO = gql`
  query GetAppMeta {
    appMeta {
      version
    }
  }
`

const compareSemVers = (currentVersion, versionAgainst) => {
  const [currentVersionOnly, currentPatchVersion] = currentVersion.split('-')
  const [againsVersionOnly, againstPatchVersion] = versionAgainst.split('-')

  const [currentMajor, currentMinor, currentPatch] = currentVersionOnly
    .split('.')
    .map(Number)
  const [againstMajor, againstMinor, againstPatch] = againsVersionOnly
    .split('.')
    .map(Number)
  return (
    currentMajor < againstMajor ||
    currentMinor < againstMinor ||
    currentPatch < againstPatch ||
    currentPatchVersion !== againstPatchVersion // WARNING: non-semantic patch version comparison
  )
}

const FALBACK_POLL_INTERVAL = 1000 * 60 * 60 * 2 // OR every 2 hours
const pollInterval = process.env.APP_META_POLL_INTERVAL || FALBACK_POLL_INTERVAL

const AppMetaContext = createContext()
export default AppMetaContext

export const AppMetaContextProvider = ({ children }) => {
  const [client] = useState({ name, version })
  const [server, setServer] = useState({})

  const [clientBehind, setClientBehind] = useState(false)

  const { data, loading, error } = useQuery(GET_APP_INFO, {
    pollInterval,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (data) {
      setServer(data.appMeta)
      setClientBehind(compareSemVers(client.version, data.appMeta.version))
    }
  }, [data, loading, error])

  const value = { client, server, clientBehind }

  return (
    <AppMetaContext.Provider value={value}>{children}</AppMetaContext.Provider>
  )
}

AppMetaContextProvider.propTypes = {
  children: PropTypes.node,
}
