import { InputAdornment, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { handleIntFieldChange } from './helpers'

export const QuantityField = ({ quantity, onChange, ...props }) => (
  <TextField
    label="Quantity"
    name="quantity"
    type="number"
    InputProps={{
      endAdornment: <InputAdornment position="end">g</InputAdornment>,
    }}
    inputProps={{ min: 0, step: 100 }}
    value={quantity || ''}
    onChange={handleIntFieldChange(onChange)}
    {...props}
  />
)
QuantityField.propTypes = {
  quantity: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}
