import { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery /*, useApolloClient */ } from '@apollo/client'

const GET_SELF = gql`
  query GetMeForContext {
    getMe {
      id
      firstName
      lastName
      email
      phoneNumber
      roles

      farms {
        id
        name
        meta
        timezone
        locale
        motherFarmId
      }
    }
  }
`

const UserContext = createContext()
export default UserContext

export const UserContextProvider = (props) => {
  const [user, setUser] = useState(null)
  // const client = useApolloClient()

  const { data, loading, error } = useQuery(GET_SELF, {
    ssr: false,
  })

  useEffect(() => {
    if (error) setUser()
    else if (data) setUser(data.getMe)
    else setUser()
  }, [data, error, loading])

  // const resetUser = async () => {
  //   // client.cache.modify({
  //   //   id: client.cache.identify(user),
  //   //   fields(fieldValue, details) {
  //   //     return details.DELETE
  //   //   },
  //   // })
  //   await setUser()
  // }

  const value = { user, loading, error }

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  )
}

UserContextProvider.propTypes = {
  children: PropTypes.node,
}
