import { useRouter } from 'next/router'

export const useProductIdParam = () => {
  const {
    isReady,
    query: { productId, ...restQuery },
    push,
  } = useRouter()

  const onProductIdParamChange = ({ productId }) =>
    push(
      { query: { ...restQuery, ...(productId && { productId }) } },
      undefined,
      { shallow: true },
    )

  const loading = !isReady

  return { loading, productId, onProductIdParamChange }
}
