import { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFarmIdParam } from 'hooks/useUrlParams'
import { useFarm } from 'components/Farm'

const FarmContext = createContext()
export default FarmContext

export const FarmContextProvider = ({ children }) => {
  const { farmId } = useFarmIdMemoized()
  const { farm, loading, error } = useFarm({ farmId })

  const value = { loading, error, farm, config: farm?.config }

  return <FarmContext.Provider value={value}>{children}</FarmContext.Provider>
}

FarmContextProvider.propTypes = {
  farmId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
}

const useFarmIdMemoized = () => {
  const [memoizedFarmId, setMemoizedFarmId] = useState()
  const { farmId } = useFarmIdParam()

  useEffect(() => farmId && setMemoizedFarmId(farmId), [farmId])

  return { farmId: memoizedFarmId }
}
