import { useState } from 'react'

export const useDialog = ({ onOpen: onOpenCallback, onClosed } = {}) => {
  const [open, setOpen] = useState(false)

  const onOpen = (...props) => {
    onOpenCallback?.(...props)
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
    onClosed?.()
  }

  return { open, onOpen, onClose }
}

export const useDialogState = ({ clearOnClose = true } = {}) => {
  const [state, setState] = useState()

  const onChange = (props) => setState({ ...state, ...props })
  const onOpen = (state) => setState(state)
  const onClosed = () => clearOnClose && setState()

  return [state, { setState, onOpen, onChange, onClosed }]
}
