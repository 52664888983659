import { DatePicker } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDateOnParam } from 'hooks'

export const DateOnField = ({ dateOn, onChange, ...props }) => (
  <DatePicker
    label="Date"
    value={dateOn}
    showTodayButton={true}
    onChange={(dateOn) => onChange({ dateOn })}
    renderInput={(props) => <TextField {...props} />}
    inputFormat="P' | Week 'I"
    {...props}
  />
)
DateOnField.propTypes = {
  dateOn: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
}

export const useDateOn = ({ useParam = false } = {}) => {
  const { dateOnParam, onDateOnParamChange } = useParam ? useDateOnParam() : {}

  const [dateOn, setDateOn] = useState(
    dateOnParam ? new Date(dateOnParam) : new Date(),
  )

  const onDateOnChange = ({ dateOn }) => {
    setDateOn(dateOn)
    onDateOnParamChange?.({ dateOn })
  }

  const onTodayChange = () => {
    const dateOn = new Date()
    setDateOn(dateOn)
    onDateOnParamChange?.({ dateOn })
  }

  return { dateOn, onDateOnChange, onTodayChange }
}
