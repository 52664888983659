import { createContext, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

const BreadcrumbContext = createContext({ breadcrumbs: [] })
export default BreadcrumbContext

const getLabelFromData = (obj) =>
  obj?.title || obj?.name || obj?.label || obj?.firstName

function convertPathToBreadcrumbs(path, data) {
  const subPaths = path.split('?')[0].split('/').filter(Boolean)
  const breadcrumbs = []
  let skipNext = false

  for (let i = 0; i < subPaths.length; i++) {
    if (skipNext) {
      skipNext = false
      continue
    }

    const fullPath = subPaths.slice(0, i + 1).join('/')
    const path = subPaths[i]
    const nextPath = subPaths[i + 1]
    const prevPath = subPaths[i - 1]
    const hasDataAboutPath = Object.keys(data)?.includes(path) && data[path]
    const hasDataAboutPrevPath =
      Object.keys(data)?.includes(prevPath) && data[prevPath]
    const nextPathIsDigit = /^\d+$/.test(nextPath)
    const currentPathIsDigit = /^\d+$/.test(path)

    const breadcrumb = {
      label: path,
      href: '/' + fullPath,
    }

    if (path === 'farm' && hasDataAboutPath && nextPathIsDigit) {
      const obj = data[path]
      const label = getLabelFromData(obj)
      if (label && obj?.id) {
        breadcrumb.label = label
        breadcrumb.href = `/${path}/${obj.id}`
        skipNext = true
      }
    } else if (hasDataAboutPrevPath && currentPathIsDigit) {
      const obj = data[prevPath]
      const label = getLabelFromData(obj)
      if (label && obj?.id) {
        breadcrumb.label = label
      }
    } else if (/^\d+$/.test(path)) {
      breadcrumb.label = '#' + path
    } else {
      const capitalized = path.charAt(0).toUpperCase() + path.slice(1)
      breadcrumb.label = capitalized
    }

    breadcrumbs.push(breadcrumb)
  }

  return breadcrumbs
}

export const BreadcrumbContextProvider = ({ children }) => {
  const { asPath } = useRouter()
  const [data, setData] = useState({})

  const upsertBreadcrumbsData = useCallback((obj) => {
    setData((existing) => ({ ...existing, ...obj }))
  }, [])

  const breadcrumbs = useMemo(
    () => convertPathToBreadcrumbs(asPath, data),
    [asPath, data],
  )

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbs, upsertBreadcrumbsData }}>
      {children}
    </BreadcrumbContext.Provider>
  )
}

BreadcrumbContextProvider.propTypes = {
  children: PropTypes.node,
}
