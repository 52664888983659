import { Paper, Stack, Typography } from '@material-ui/core'
import { XGrid } from '@material-ui/x-grid'
import { DeleteIconButton, EditIconButton } from 'components/Buttons'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

export const CurrencyList = ({
  loading,
  currencies = [],
  ActionsComponent,
}) => (
  <Fragment>
    <Paper>
      <Typography variant="h5" p={2}>
        <b>Currencies</b>
      </Typography>
      <XGrid
        rowHeight={38}
        rows={currencies}
        hideFooter={true}
        pagination={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        loading={loading}
        sx={{ border: 'none !important' }}
        columns={[
          {
            headerName: 'Id',
            field: 'id',
          },

          {
            headerName: 'Code',
            field: 'code',
            type: 'string',
            width: 120,
          },

          {
            headerName: 'Symbol',
            field: 'symbol',
            type: 'string',
            width: 150,
          },

          {
            headerName: 'Divisor',
            field: 'divisor',
            type: 'number',
            width: 150,
          },

          ...(ActionsComponent
            ? [
                {
                  headerName: '',
                  headerAlign: 'right',
                  align: 'right',
                  sortable: false,
                  field: 'Actions',
                  flex: 1,
                  renderCell: ({ row: currency }) => (
                    <ActionsComponent currency={currency} />
                  ),
                },
              ]
            : []),
        ]}
      />
    </Paper>
  </Fragment>
)
CurrencyList.propTypes = {
  loading: PropTypes.bool.isRequired,
  currencies: PropTypes.array,
  ActionsComponent: PropTypes.node,
}

export const CurrencyActions = ({ onDelete, onEdit, currency }) => (
  <Stack direction="row">
    <EditIconButton onClick={() => onEdit({ currency })} />
    <DeleteIconButton onClick={() => onDelete({ currency })} />
  </Stack>
)
CurrencyActions.propTypes = {
  currency: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}
