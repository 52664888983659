import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography, IconButton, Box, Fab } from '@material-ui/core'
import { XGrid } from '@material-ui/x-grid'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/styles'
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate'
import AddIcon from '@material-ui/icons/Add'
import { FarmDialog } from '..'

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
  noBorder: {
    border: 'none !important',
  },
  table: {
    border: 'none !important',
    '& .MuiDataGrid-cell:last-child': {
      flexDirection: 'row-reverse',
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}))

export function FarmListComponent({
  editingFarm,
  farms,
  forceAddNew,
  handleClose,
  loading,
  onFarmClick,
  onEditFarmClick,
  onFabAddButtonClick,
  openDialog,
  openPrefilledDialog,
}) {
  const classes = useStyles()

  return (
    <Fragment>
      <Typography variant="h4" p={2} pb={4} pl={0}>
        <b>System</b> Farm administration
      </Typography>
      <Paper>
        <XGrid
          rowHeight={38}
          rows={farms}
          hideFooter={true}
          pagination={false}
          autoHeight={true}
          disableSelectionOnClick={true}
          loading={loading}
          onRowClick={(params, ...rest) => {
            debugger
            onFarmClick(params.row)
          }}
          className={classes.table}
          columns={[
            {
              headerName: 'Name',
              field: 'name',
              type: 'string',
              width: 150,
            },
            {
              headerName: 'Address',
              field: 'address',
              type: 'string',
              flex: 1,
              valueGetter: ({ row }) => row.meta.company?.address,
            },
            {
              headerName: 'Company name',
              field: 'companyName',
              type: 'string',
              valueGetter: ({ row }) => row.meta.company?.name,
              width: 180,
            },
            {
              headerName: 'CVR',
              field: 'companyCvr',
              type: 'string',
              valueGetter: ({ row }) => row.meta.invoiceInfo?.cvr,
              width: 140,
            },
            {
              headerName: 'Contact name',
              field: 'contactName',
              type: 'string',
              width: 180,
              valueGetter: ({ row }) =>
                `
                  ${row.meta.contactInfo?.person?.firstName || ''}${' '}
                  ${row.meta.contactInfo?.person?.lastName || ''}
                `,
            },
            {
              headerName: 'Contact email',
              field: 'contactEmail',
              type: 'string',
              width: 180,
              valueGetter: ({ row }) => row.meta?.contactInfo?.email || '-',
            },
            {
              headerName: 'Contact phone',
              field: 'contactPhone',
              type: 'string',
              width: 160,
              valueGetter: ({ row }) => row.meta?.contactInfo?.phone || '-',
            },
            {
              headerName: ' ',
              field: 'actions',
              sortable: false,
              width: 100,
              // eslint-disable-next-line react/display-name
              renderCell: (params) => {
                return (
                  <Fragment>
                    <Box mr={1}>
                      <IconButton
                        size="small"
                        aria-label="edit farm"
                        aria-haspopup="true"
                        onClick={() => onEditFarmClick(params.row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                    <Box mr={2}>
                      <IconButton
                        size="small"
                        aria-label="create prefilled farm"
                        aria-haspopup="true"
                        onClick={() => openPrefilledDialog(params.row)}
                      >
                        <ControlPointDuplicateIcon />
                      </IconButton>
                    </Box>
                  </Fragment>
                )
              },
            },
          ]}
        />
      </Paper>
      <FarmDialog
        open={openDialog}
        handleClose={handleClose}
        forceAddNew={forceAddNew}
        farmItem={editingFarm}
        refetchQueries={['GetFarms']}
      />
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={onFabAddButtonClick}
      >
        <AddIcon />
      </Fab>
    </Fragment>
  )
}

FarmListComponent.propTypes = {
  editingFarm: PropTypes.object,
  farms: PropTypes.array.isRequired,
  forceAddNew: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onEditFarmClick: PropTypes.func.isRequired,
  onFabAddButtonClick: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  openPrefilledDialog: PropTypes.func.isRequired,
}
