import { useMutation } from '@apollo/client'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import PropTypes from 'prop-types'

import { useErrorReporting } from 'hooks'
import { GOOGLE_AUTH } from '../queries'

export const GoogleButton = ({ onLoggedIn }) => {
  const [googleAuth] = useMutation(GOOGLE_AUTH, {
    refetchQueries: ['GetMeForContext', 'GetFarmForContext'],
  })

  const onSuccess = async ({ credential: idToken }) => {
    const {
      data: {
        signInGoogleOAuth: { id },
      },
    } = await googleAuth({ variables: { idToken } })

    onLoggedIn && onLoggedIn({ user: { id } })
  }

  const { onError } = useErrorReporting()

  return (
    <GoogleOAuthProvider
      clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}
    >
      <GoogleLogin
        auto_select
        hosted_domain="nabofarm.com"
        onError={(message) => onError({ message })}
        onSuccess={onSuccess}
        size="large"
      />
    </GoogleOAuthProvider>
  )
}

GoogleButton.propTypes = {
  onLoggedIn: PropTypes.func,
}
