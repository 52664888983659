import { useGetCurrency } from 'components/Currency'
import { useFarm } from 'hooks'
import { FarmConfig } from 'types'

export const useFarmConfig = (key) => {
  const { farm } = useFarm()
  return farm?.config[key]
}

export const useFarmCurrency = (props) => {
  const currencyId = useFarmConfig(FarmConfig.DEFAULT_CURRENCY_ID)
  const { currency, ...rest } = useGetCurrency({ currencyId, ...props })

  return { currency, ...rest }
}
