import { TextField } from '@material-ui/core'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { handleFieldChange } from './helpers'

export const SearchField = ({ onSearch, ...props }) => (
  <TextField
    name="search"
    label="Search products"
    variant="outlined"
    size="small"
    onInput={handleFieldChange(onSearch)}
    {...props}
  />
)
SearchField.propTypes = {
  onSearch: PropTypes.func.isRequired,
}

export const useSearch = () => {
  const [search, setSearch] = useState()
  const onSearch = ({ search }) =>
    search ? setSearch(new RegExp(search, 'ig')) : setSearch()

  return { search, onSearch }
}
