import {
  Box,
  Link,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useState } from 'react'
import { LoginForm } from './Form/Container'
import { GoogleButton } from './GoogleButton'
import { RecoveryForm } from './Recovery/Container'

const useStyles = makeStyles(() => ({
  forgot: {
    textDecoration: 'none',
  },
  logo: {
    maxWidth: '200px',
  },
  video: {
    position: 'fixed',
    overflow: 'hidden',
    top: '50%',
    left: '50%',
    minWidth: 'auto',
    minHeight: 'auto',
    zIndex: '-10',
    transform: 'translateX(-50%) translateY(-50%)',
    filter: 'blur(6px)',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: 'green',
    opacity: '0.6',
    zIndex: '-1',
  },
}))

export const LoginScreen = () => {
  const onLoggedIn = ({ user }) => {
    console.log('signed in as:', user)
  }
  const [inRecovery, setInRecovery] = useState(false)
  const classes = useStyles()

  return (
    <Box
      sx={{
        background: 'url("/login-background.jpg") no-repeat top left  / cover',
      }}
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent={{ xs: 'center', md: 'right' }}
      px={{ xs: 3, sm: 10, md: 22 }}
    >
      <div className={classes.overlay}></div>
      {/* <video className={classes.video} autoPlay muted loop>
        <source src="/background-video.mp4" type="video/mp4" />
      </video> */}

      <Paper>
        <Box
          minWidth="350px"
          display="flex"
          direction="column"
          alignItems="center"
          justifyContent="center"
          p={5}
        >
          <Stack direction="column" spacing={4}>
            <Collapse in={!inRecovery}>
              <Box textAlign="center">
                <Box
                  component="img"
                  className={classes.logo}
                  src="/naboOS-logo-v2.png"
                  mt={1}
                  mb={6}
                />
              </Box>
              <LoginForm onLoggedIn={onLoggedIn} />
              <Box textAlign="right" pt={1}>
                <Link
                  onClick={() => setInRecovery(true)}
                  style={{ cursor: 'pointer' }}
                  className={classes.forgot}
                >
                  <Typography variant="small">Forgot password?</Typography>
                </Link>
              </Box>
              <Box py={3}>
                <Divider>or</Divider>
              </Box>
              <GoogleButton onLoggedIn={onLoggedIn} />
            </Collapse>

            <Collapse in={inRecovery}>
              <IconButton onClick={() => setInRecovery(false)}>
                <ArrowBack />
              </IconButton>
              <RecoveryForm
                onSubmit={(props) => console.log('onSubmit', props)}
              />
            </Collapse>
          </Stack>
        </Box>
      </Paper>
    </Box>
  )
}
