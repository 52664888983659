import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Form } from './Component'
import { SIGN_IN } from '../../queries'

export const LoginForm = ({ onLoggedIn }) => {
  const [credentials, setCredentials] = useState()

  const [signIn] = useMutation(SIGN_IN, {
    refetchQueries: ['GetMeForContext', 'GetFarmForContext'],
  })

  const onSubmit = async () => {
    const { data } = await signIn({ variables: credentials })

    onLoggedIn?.({ user: data.signIn })
  }

  const onChange = (value) => setCredentials({ ...credentials, ...value })

  return (
    <Form credentials={credentials} onSubmit={onSubmit} onChange={onChange} />
  )
}

LoginForm.propTypes = {
  onLoggedIn: PropTypes.func,
}
