import { useRouter } from 'next/router'
import { useEffect, useState, useMemo } from 'react'

export const useId = () => {
  const { isReady, query } = useRouter()
  const [id, setId] = useState()

  const setIdFromUrl = useMemo(
    () => isReady && !isNaN(parseInt(query?.id)),
    [isReady, query?.id],
  )

  useEffect(
    () => setIdFromUrl && setId(query.id),
    [isReady, query, setIdFromUrl],
  )

  return id
}
