export const ON_DEMAND = 'on-demand'
export const PERIOD = 'period'

export const InstructionType = {
  ON_DEMAND,
  PERIOD,
}

const ReadableInstructionTypes = {
  ON_DEMAND: 'Not recurring',
  PERIOD: 'Recurring',
}

export function getReadableInstructionType(value) {
  if (!Object.keys(InstructionType).includes(value)) {
    throw new Error(`Instruction type '${value}' does not exist`)
  } else {
    return ReadableInstructionTypes[value]
  }
}
