import { AddFab } from 'components/Buttons'

import PropTypes from 'prop-types'
import {
  CurrencyActions,
  CurrencyDeleteDialog,
  CurrencyDialog,
  CurrencyList,
  useCurrencyDialog,
  useGetCurrencies,
  useDeleteDialog,
} from '.'

export const CurrencyAdmin = ({ onError }) => {
  const { currencies, loading } = useGetCurrencies({ onError })
  const { onOpen, ...dialogProps } = useCurrencyDialog({ onError })
  const { onConfirmDelete, ...confirmProps } = useDeleteDialog({ onError })

  return (
    <>
      <CurrencyList
        ActionsComponent={({ currency }) => (
          <CurrencyActions
            onDelete={onConfirmDelete}
            onEdit={onOpen}
            currency={currency}
          />
        )}
        loading={loading}
        currencies={currencies}
      />

      <CurrencyDialog {...dialogProps} />

      <CurrencyDeleteDialog {...confirmProps} />

      <AddFab onClick={() => onOpen()} />
    </>
  )
}
CurrencyAdmin.propTypes = {
  onError: PropTypes.func.isRequired,
}
