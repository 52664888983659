import { gql } from '@apollo/client'

export const GOOGLE_AUTH = gql`
  mutation GoogleAuth($idToken: String!) {
    signInGoogleOAuth(idToken: $idToken) {
      id
    }
  }
`

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      id
    }
  }
`

export const SEND_RECOVERY = gql`
  mutation ($email: String!) {
    requestUserRecovery(email: $email)
  }
`
