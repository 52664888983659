import { useState, useEffect, useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { useSnacks } from '../../Providers/Contexts/SnackQueue'
import { ADD_FARM, EDIT_FARM } from '../queries'
import { FarmDialogComponent } from './Component'
import { assignDeep } from '../../utils'

const defaultLocale = 'da-DK'
const defaultTimezone = 'Europe/Copenhagen'
const defaultFormData = {
  initials: '',
  name: '',
  timezone: defaultTimezone,
  locale: defaultLocale,
  meta: {
    company: {},
    invoiceInfo: {},
    contactInfo: {
      person: {},
    },
  },
}

export function FarmDialog({
  open,
  farmItem,
  handleClose,
  refetchQueries,
  forceAddNew,
}) {
  const { snack } = useSnacks()
  const [formData, setFormData] = useState(defaultFormData)

  const isEditing = useMemo(() => !!farmItem && !forceAddNew)

  // update fields when farmItem changes
  useEffect(() => {
    if (farmItem) {
      setFormData({ ...farmItem })
    } else {
      setFormData(defaultFormData)
    }
  }, [farmItem])

  const [addFarm] = useMutation(ADD_FARM, {
    refetchQueries,
    onCompleted: () => snack('Farm successfully created'),
  })
  const [editFarm] = useMutation(EDIT_FARM, {
    refetchQueries,
    onCompleted: () => snack('Farm successfully updated'),
  })

  const handleSubmit = useCallback(async () => {
    const mutation = isEditing ? editFarm : addFarm

    if (farmItem?.id && forceAddNew) {
      setFormData({ ...formData, id: null })
    }

    await mutation({ variables: formData })
    handleClose()
  }, [isEditing, farmItem?.id, handleClose, formData])

  const handleMotherFarmChange = useCallback(
    (farmId) => {
      const parsedId = parseInt(farmId)
      if (isNaN(parsedId) || parsedId === -1) {
        setFormData({ ...formData, motherFarmId: null })
      } else {
        setFormData({ ...formData, motherFarmId: parsedId })
      }
    },
    [formData],
  )

  const patchFormData = useCallback(
    (args) => {
      const patched = assignDeep(formData, args)
      setFormData(patched)
    },
    [formData],
  )

  return (
    <FarmDialogComponent
      handleMotherFarmChange={handleMotherFarmChange}
      handleSubmit={handleSubmit}
      formData={formData}
      patchFormData={patchFormData}
      handleClose={handleClose}
      isEditing={isEditing}
      open={open}
    />
  )
}

FarmDialog.propTypes = {
  farmItem: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  refetchQueries: PropTypes.arrayOf(String),
  forceAddNew: PropTypes.bool.isRequired,
}
