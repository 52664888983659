import {
  createContext,
  useState,
  useReducer,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'

const SnackQueue = createContext({ queue: [] })

function snackReducer(state, action) {
  switch (action.type) {
    case 'push': {
      state.queue.push(action.payload)
      return { queue: state.queue }
    }
    case 'shift': {
      state.queue.shift()
      return { queue: state.queue }
    }
  }
}

export function SnackContextProvider({ children }) {
  const [state, dispatch] = useReducer(snackReducer, { queue: [] })
  const [closing, setClosing] = useState(false)

  const close = useCallback(() => {
    setClosing(true)
    setTimeout(() => dispatch({ type: 'shift' }), 300)
    setTimeout(() => setClosing(false), 600)
  }, [])

  const snack = useCallback(
    (msg, style = 'success', timeout = 7000) => {
      const autoClose = () => setTimeout(close, timeout)
      const manualClose = () => {
        close()
        autoClose && clearTimeout(autoClose)
      }
      dispatch({
        type: 'push',
        payload: { msg, style, manualClose, autoClose },
      })
    },
    [dispatch],
  )

  const currentSnack = useMemo(
    () => (state.queue.length ? state.queue[0] : undefined),
    [state],
  )

  return (
    <SnackQueue.Provider
      value={{ closing, queue: state.queue, snack, currentSnack }}
    >
      {children}
    </SnackQueue.Provider>
  )
}

export function useSnacks() {
  const context = useContext(SnackQueue)
  if (context === undefined) {
    throw new Error('useSnacks must be used within the snack provider')
  }
  return context
}

SnackContextProvider.propTypes = {
  children: PropTypes.node,
}
