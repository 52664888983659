import { gql } from '@apollo/client'

export const CORE_BATCH_FIELDS = gql`
  fragment CoreBatchFields on Batch {
    id
    startAt
    hasStarted
    harvestAt
    spoilsAt
    yieldPlanned
    yieldAssigned
    surplusAdjustment
    yieldSurplus
    nrOfTrays
    nrOfTraysBatched
    seedsTotal
    seedsPerTray
    deprecatedRecipe
  }
`

export const CORE_SHIPPING_PACKAGE_TYPE_FIELDS = gql`
  fragment CoreShippingPackageTypeFields on ShippingPackageType {
    id
    name
    maxProductWeight
  }
`

export const CORE_PACKAGE_TYPE_FIELDS = gql`
  fragment CorePackageTypeFields on PackageType {
    id
    name
    productWeight
    trayQuantity
    title
    updatedAt

    shippingPackageOptions {
      # ...CoreShippingPackageOptionFields
      id
      slotQuantity
      shippingPackageType {
        id
        name
      }
    }
  }
`

export const CORE_CURRENCY_FIELDS = gql`
  fragment CoreCurrencyFields on Currency {
    id
    code
    symbol
    divisor
  }
`

export const CORE_ORDER_FIELDS = gql`
  ${CORE_CURRENCY_FIELDS}
  fragment CoreOrderFields on Order {
    id
    quantity
    deliveredQuantity
    unitPrice
    currency {
      ...CoreCurrencyFields
    }
    deliverOn
  }
`

export const CORE_NOTE_FIELDS = gql`
  fragment CoreNoteFields on Note {
    id
  }
`

export const CORE_TRAY_TYPE_FIELDS = gql`
  fragment CoreTrayTypeFields on TrayType {
    id
    name
    area
    sellable
    bulkFactor
    bulkedName

    shippingPackageOptions {
      # ...CoreShippingPackageOptionFields
      id
      slotQuantity
      shippingPackageType {
        id
        name
      }
    }
  }
`

export const PRODUCT_INSTRUCTION_FIELDS = gql`
  fragment ProductInstructionFields on ProductInstruction {
    id
    dueFromStart(unit: PARTS) {
      ... on DurationParts {
        minutes
        hours
        days
        unit
      }
    }
    textTemplate
    description
    tags {
      id
      label
      color
    }
  }
`

export const CORE_PRODUCT_FIELDS = gql`
  ${CORE_SHIPPING_PACKAGE_TYPE_FIELDS}
  ${CORE_PACKAGE_TYPE_FIELDS}
  ${CORE_TRAY_TYPE_FIELDS}
  fragment CoreProductFields on Product {
    id
    title
    name
    crop
    seedName
    variant
    yielding
    definitionChangedAt

    productionTime {
      __typename
      ... on DurationValue {
        value
        unit
      }
    }

    trayType {
      ...CoreTrayTypeFields
    }

    defaultPackageShippingOption {
      id
      shippingPackageType {
        ...CoreShippingPackageTypeFields
      }
      slotQuantity
    }
    defaultTrayShippingOption {
      id
      shippingPackageType {
        ...CoreShippingPackageTypeFields
      }
      slotQuantity
    }

    defaultShippingPackageType {
      ...CoreShippingPackageTypeFields
    }

    defaultPackageType {
      ...CorePackageTypeFields
    }
  }
`

export const COMMON_ORDER_FIELDS = gql`
  ${CORE_BATCH_FIELDS}
  ${CORE_CURRENCY_FIELDS}
  ${CORE_ORDER_FIELDS}
  ${CORE_PACKAGE_TYPE_FIELDS}
  ${CORE_PRODUCT_FIELDS}
  ${CORE_SHIPPING_PACKAGE_TYPE_FIELDS}
  ${CORE_SHIPPING_PACKAGE_TYPE_FIELDS}
  ${CORE_TRAY_TYPE_FIELDS}
  fragment CommonOrderFields on Order {
    ...CoreOrderFields

    totalPrice
    finishedAt

    customer {
      id
      name
    }

    orderGroup {
      id
      dayInterval
    }

    packageQuantity
    trayQuantity

    packageType {
      ...CorePackageTypeFields
    }

    product {
      ...CoreProductFields

      trayType {
        ...CoreTrayTypeFields
      }
    }

    shippingPackageType {
      ...CoreShippingPackageTypeFields
    }
    shippingPackageQuantity

    batch {
      ...CoreBatchFields

      trayType {
        ...CoreTrayTypeFields
        sellable
      }
    }
  }
`

export const CORE_BATCH_FIELDS_EXTENDED = gql`
  fragment CoreBatchFieldsExtended on Batch {
    ...CoreBatchFields

    product {
      ...CoreProductFields
    }

    trayType {
      ...CoreTrayTypeFields
    }

    notes {
      ...CoreNoteFields
    }
  }
  ${CORE_BATCH_FIELDS}
  ${CORE_NOTE_FIELDS}
  ${CORE_PRODUCT_FIELDS}
  ${CORE_TRAY_TYPE_FIELDS}
`

export const CORE_CONFIG_FIELDS = gql`
  fragment CoreConfigFields on Config {
    id
    key
    value
  }
`

export const CORE_CUSTOMER_FIELDS = gql`
  ${CORE_CURRENCY_FIELDS}

  fragment CoreCustomerFields on Customer {
    id
    name
    cvr
    address
    contact
    subscriptionUntil
    deliveryTime

    currency {
      ...CoreCurrencyFields
    }
  }
`

export const CORE_FARM_FIELDS = gql`
  fragment CoreFarmFields on Farm {
    id
    name
    meta
    timezone
    locale
    motherFarmId
  }
`

export const FARM_USER_FRAGMENT = gql`
  fragment CoreFarmUserFragment on FarmUserRoles {
    farm {
      id
    }

    user {
      id
      name
      email
      roles
    }

    roles
  }
`

export const CORE_TEMPLATE_FIELDS = gql`
  fragment CoreTemplateFields on InstructionTemplate {
    id
    text
  }
`

export const ADJUSTMENT_FRAGMENT = gql`
  fragment CoreAdjustmentFields on InventoryItemAdjustment {
    id
    reason
    quantity
    createdAt
    activateAt
    isActivated
    isManuallyCreated
    user {
      name
    }
    shipment {
      id
      slug
    }
  }
`

export const SHIPMENT_FRAGMENT = gql`
  fragment CoreShipmentFields on InventoryShipment {
    id
    quantityLeft
    quantityOrdered
    queueIndex
    deliveredAt
    createdAt
    slug
    user {
      name
    }
    procurementOrderLine {
      id
      totalPrice
      unitPrice
      procurementOrder {
        supplierName
      }
    }
  }
`

export const INVENTORY_ITEM_FRAGMENT = gql`
  fragment CoreInventoryItemFields on InventoryItem {
    id
    name
    totalQuantity
    createdAt
    quantityUnit
    depletedAt
    restockReminder
    defaultHbKey
    hasPendingShipments
  }
`

export const CORE_TAG_FIELDS = gql`
  fragment CoreTagFields on Tag {
    id
    label
    color
  }
`

export const COMMON_NOTE_FIELDS = gql`
  fragment CommonNoteFields on Note {
    id
    user {
      id
      firstName
    }
    meta
    text
    createdAt
    tags {
      id
      label
      color
    }
  }
`

export const USER_FRAGMENT = gql`
  fragment CoreUserFragment on User {
    id
    name
    email
    roles
  }
`

export const CORE_INSTRUCTION_FIELDS = gql`
  fragment CoreInstructionFields on FarmInstruction {
    id

    active
    textTemplate
    description

    type
    interval(unit: PARTS) {
      ... on DurationParts {
        hours
        minutes
        days
        unit
      }
    }

    scheduleAt
    scheduledAt

    meta
  }
`

export const PROCUREMENT_ORDER_FRAGMENT = gql`
  fragment CoreProcurementOrderFields on ProcurementOrder {
    id
    procurementOrderLines {
      id
      description
      quantity
      unit
      unitPrice
      totalPrice
    }
    deliveryAt
    deliveryPrice
    supplierName
    orderNumber
    createdAt
    currency
    totalPrice
  }
`

export const CORE_PRODUCT_PRICE_FIELDS = gql`
  ${CORE_CURRENCY_FIELDS}
  fragment CoreProductPriceFields on ProductPrice {
    id
    updatedAt
    price
    currency {
      ...CoreCurrencyFields
    }

    product {
      id
      name
      title
    }
    customer {
      id
      name
    }
    packageType {
      id
      title
      name
      productWeight
    }
    trayType {
      id
      name
    }
  }
`

export const CORE_SHIPPING_PACKAGE_OPTION_FIELDS = gql`
  ${CORE_SHIPPING_PACKAGE_TYPE_FIELDS}

  fragment CoreShippingPackageOptionFields on ShippingPackageOption {
    id
    slotQuantity
    shippingPackageType {
      ...CoreShippingPackageTypeFields
    }
    __typename
  }
`

export const CORE_CONTAINER_PRICE_FIELDS = gql`
  ${CORE_CURRENCY_FIELDS}
  ${CORE_CUSTOMER_FIELDS}
  ${CORE_PACKAGE_TYPE_FIELDS}
  ${CORE_SHIPPING_PACKAGE_TYPE_FIELDS}
  ${CORE_TRAY_TYPE_FIELDS}

  fragment CoreContainerPriceFields on ContainerPrice {
    id
    amount

    currency {
      ...CoreCurrencyFields
    }
    customer {
      ...CoreCustomerFields
    }
    packageType {
      ...CorePackageTypeFields
    }
    shippingPackageType {
      ...CoreShippingPackageTypeFields
    }
    trayType {
      ...CoreTrayTypeFields
    }
  }
`
