import { gql, useMutation, useQuery } from '@apollo/client'
import { CORE_CURRENCY_FIELDS } from 'components/queries'

const refetchQueries = ['GetCurrencies', 'GetCurrency']

const GET_CURRENCIES = gql`
  ${CORE_CURRENCY_FIELDS}
  query GetCurrencies {
    getCurrencies {
      ...CoreCurrencyFields
    }
  }
`
export const useGetCurrencies = (props) => {
  const { data, ...rest } = useQuery(GET_CURRENCIES, props)

  return { currencies: data?.getCurrencies, ...rest }
}

const GET_CURRENCY = gql`
  ${CORE_CURRENCY_FIELDS}
  query GetCurrency($currencyId: ID!) {
    getCurrency(currencyId: $currencyId) {
      ...CoreCurrencyFields
    }
  }
`
export const useGetCurrency = ({ currencyId, ...props }) => {
  const { data, ...rest } = useQuery(GET_CURRENCY, {
    variables: { currencyId },
    skip: !currencyId,
    ...props,
  })
  return { currency: data?.getCurrency, ...rest }
}

const CREATE_CURRENCY = gql`
  ${CORE_CURRENCY_FIELDS}
  mutation CreateCurrency(
    $code: CurrencyCode!
    $divisor: Int!
    $symbol: String!
  ) {
    createCurrency(code: $code, divisor: $divisor, symbol: $symbol) {
      ...CoreCurrencyFields
    }
  }
`
export const useCreateCurrency = (props) => {
  const [create, rest] = useMutation(CREATE_CURRENCY, {
    refetchQueries,
    ...props,
  })

  const onCreate = ({ code, symbol, divisor }) =>
    create({ variables: { code, divisor, symbol } })

  return { onCreate, ...rest }
}

const EDIT_CURRENCY = gql`
  ${CORE_CURRENCY_FIELDS}
  mutation EditCurrency(
    $currencyId: ID!
    $code: CurrencyCode
    $divisor: Int
    $symbol: String
  ) {
    editCurrency(
      currencyId: $currencyId
      code: $code
      divisor: $divisor
      symbol: $symbol
    ) {
      ...CoreCurrencyFields
    }
  }
`
export const useEditCurrency = ({ currency, ...props }) => {
  const [edit, rest] = useMutation(EDIT_CURRENCY, {
    refetchQueries,
    ...props,
  })

  const onEdit = ({ code, symbol, divisor }) =>
    edit({ variables: { code, currencyId: currency?.id, divisor, symbol } })

  return { onEdit, ...rest }
}

const DELETE_CURRENCY = gql`
  mutation DeleteCurrency($currencyId: ID!) {
    deleteCurrency(currencyId: $currencyId) {
      id
    }
  }
`

export const useDeleteCurrency = (props) => {
  const [remove, rest] = useMutation(DELETE_CURRENCY, {
    refetchQueries,
    ...props,
  })

  const onDelete = ({ currency }) =>
    remove({ variables: { currencyId: currency?.id || props?.currency?.id } })

  return { onDelete, ...rest }
}
