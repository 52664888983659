import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../src/theme'
import CssBaseline from '@material-ui/core/CssBaseline'

export const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

Theme.propTypes = {
  children: PropTypes.node.isRequired,
}
