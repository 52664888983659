import { useMemo } from 'react'
import { useRouter } from 'next/router'

export const useTabKeyParam = ({ initialKey }) => {
  const { isReady, query, push } = useRouter()

  const tabKey = useMemo(() => query?.tabKey || initialKey, [query?.tabKey])

  const onChange = ({ tabKey }) =>
    push({ query: { ...query, tabKey } }, undefined, { shallow: true })

  return { loading: isReady, tabKey, onChange }
}
