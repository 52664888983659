import { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { GET_FARMS } from '../queries'
import { FarmPickerComponent } from './Component'
import { useUser, useFarm } from '../../../hooks'
import * as SystemRoles from '../../../types/system-role'

export function FarmPicker({ onFarmChange, wide = true }) {
  const [anchorEl, setAnchorEl] = useState()
  const openDropdown = useMemo(() => !!anchorEl, [anchorEl])
  const { user } = useUser()
  const { farm, loading } = useFarm()

  const shouldFetchAllFarms = useMemo(() => {
    return user
      ? user.roles.some((role) =>
          [SystemRoles.ADMIN, SystemRoles.OWNER].includes(role),
        )
      : false
  }, [user])

  const [fetchAllFarms, { data: allFarms }] = useLazyQuery(GET_FARMS)

  useEffect(() => {
    shouldFetchAllFarms && fetchAllFarms()
  }, [shouldFetchAllFarms])

  const farms = useMemo(() => {
    if (shouldFetchAllFarms && allFarms?.length) {
      return allFarms
    } else if (!shouldFetchAllFarms && user?.farms?.length) {
      return user.farms
    } else {
      return []
    }
  }, [shouldFetchAllFarms, user, allFarms])

  const { push, pathname } = useRouter()

  const defaultOnFarmChange = useCallback(
    (farm) => {
      const farmIdRegex = /\[farmId\]/gi
      let newPath = `/farm/${farm.id}`

      if (farmIdRegex.test(pathname)) {
        newPath = pathname.replace(farmIdRegex, `${farm.id}`)
      }

      if (farm && push) {
        push(newPath)
      }
    },
    [push, pathname],
  )

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])
  const handleFarmClick = useCallback(
    (_event, farm) => {
      setAnchorEl(null)
      if (typeof onFarmChange === 'function') {
        onFarmChange(farm)
      } else {
        defaultOnFarmChange(farm)
      }
    },
    [onFarmChange, defaultOnFarmChange],
  )

  return (
    <FarmPickerComponent
      loading={!!loading}
      openDropdown={openDropdown}
      handleClick={handleClick}
      handleClose={handleClose}
      handleFarmClick={handleFarmClick}
      wide={wide}
      farm={farm}
      anchorEl={anchorEl}
      farms={farms}
    />
  )
}

FarmPicker.propTypes = {
  onFarmChange: PropTypes.func,
  wide: PropTypes.bool,
}
