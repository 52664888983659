import { useMemo } from 'react'

export const useFilter = ({ toFilter, filters = [] }) => {
  const filtered = useMemo(
    () =>
      filters?.length
        ? toFilter?.reduce(
            (filtered = [], element) => [
              ...filtered,
              ...filters.reduce(
                ([filtered], filter) => filter(filtered),
                [element],
              ),
            ],
            [],
          )
        : toFilter,
    [toFilter, filters],
  )

  return filtered
}

export const filter = {
  customerForCustomerId: (customerFor) => (customer) =>
    customer?.id === customerFor.id ? [customer] : [],

  customerForNotCustomerId: (customerNotFor) => (customer) =>
    customer?.id !== customerNotFor.id ? [customer] : [],

  batchForProductId: (productFor) => (batch) =>
    batch?.product?.id === productFor?.id ? [batch] : [],

  customerOrdersForProductId: (productFor) => (c) => {
    const orders = c?.orders?.filter(
      (order) => order?.product?.id === productFor?.id,
    )
    return orders?.length ? [{ ...c, orders }] : []
  },

  orderForProductId: (productFor) => (order) =>
    order?.product?.id === productFor?.id ? [order] : [],
}
