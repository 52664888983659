import { Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import { FarmDropDown } from '..'
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { palette } from 'src/theme'

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '380px',
    },
  },
  inputGrid: {
    '& .MuiInputBase-root': {
      marginBottom: theme.spacing(2),
      backgroundColor: palette.common.white,
    },
    '& .MuiFormControl-root': {
      backgroundColor: palette.background.default,
    },
  },
}))

export function FarmDialogComponent({
  open,
  isEditing,
  handleClose,
  patchFormData,
  formData,
  handleSubmit,
  handleMotherFarmChange,
}) {
  const classes = useStyles()

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        maxWidth="lg"
      >
        <DialogTitle>{isEditing ? 'Edit farm' : 'Create farm'}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} className={classes.inputGrid}>
            <Grid item xs={6}>
              <TextField
                label="Farm name"
                fullWidth
                value={formData.name}
                size="small"
                onInput={(e) => patchFormData({ name: e.target.value })}
              />
              <TextField
                label="Company name"
                fullWidth
                value={formData.meta.company.name}
                size="small"
                onInput={(e) =>
                  patchFormData({ meta: { company: { name: e.target.value } } })
                }
              />
              <TextField
                label="Company address"
                fullWidth
                value={formData.meta.company.address}
                size="small"
                onInput={(e) =>
                  patchFormData({
                    meta: { company: { address: e.target.value } },
                  })
                }
              />
              <TextField
                label="Country"
                fullWidth
                value={formData.meta.company.country}
                size="small"
                onInput={(e) =>
                  patchFormData({
                    meta: { company: { country: e.target.value } },
                  })
                }
              />
              <TextField
                label="CVR"
                fullWidth
                value={formData.meta.invoiceInfo.cvr}
                size="small"
                onInput={(e) =>
                  patchFormData({
                    meta: { invoiceInfo: { cvr: e.target.value } },
                  })
                }
              />
              <TextField
                label="CVRP"
                fullWidth
                value={formData.meta.invoiceInfo.cvrp}
                size="small"
                onInput={(e) =>
                  patchFormData({
                    meta: { invoiceInfo: { cvrp: e.target.value } },
                  })
                }
              />
              <TextField
                label="Color"
                fullWidth
                value={formData.meta.color}
                size="small"
                onInput={(e) =>
                  patchFormData({
                    meta: { color: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Contact firstname"
                fullWidth
                value={formData.meta.contactInfo.person.firstName}
                size="small"
                onInput={(e) =>
                  patchFormData({
                    meta: {
                      contactInfo: { person: { firstName: e.target.value } },
                    },
                  })
                }
              />
              <TextField
                label="Contact lastname"
                fullWidth
                value={formData.meta.contactInfo.person.lastName}
                size="small"
                onInput={(e) =>
                  patchFormData({
                    meta: {
                      contactInfo: { person: { lastName: e.target.value } },
                    },
                  })
                }
              />
              <TextField
                label="Contact email"
                fullWidth
                size="small"
                value={formData.meta.contactInfo.email}
                onInput={(e) =>
                  patchFormData({
                    meta: {
                      contactInfo: { email: e.target.value },
                    },
                  })
                }
              />
              <TextField
                label="Contact phone"
                fullWidth
                size="small"
                value={formData.meta.contactInfo.phone}
                onInput={(e) =>
                  patchFormData({
                    meta: {
                      contactInfo: { phone: e.target.value },
                    },
                  })
                }
              />
              <TextField
                label="Timezone"
                fullWidth
                size="small"
                value={formData.timezone}
                onInput={(e) => patchFormData({ timezone: e.target.value })}
              />
              <TextField
                label="Locale"
                fullWidth
                size="small"
                value={formData.locale}
                onInput={(e) => patchFormData({ locale: e.target.value })}
              />
              <TextField
                label="Initials"
                fullWidth
                size="small"
                value={formData.meta.initials}
                onInput={(e) =>
                  patchFormData({ meta: { initials: e.target.value } })
                }
              />
              <FarmDropDown
                label="Mother farm"
                value={
                  (formData.motherFarmId && parseInt(formData.motherFarmId)) ||
                  -1
                }
                onSelect={handleMotherFarmChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button color="primary" variant="outlined" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

FarmDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  farmItem: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  patchFormData: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleMotherFarmChange: PropTypes.func.isRequired,
}
