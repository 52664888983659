import { Button, Stack, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

export const Form = ({ email, onChange, onSubmit }) => {
  return (
    <Stack direction="column" spacing={2}>
      <TextField
        label="Email"
        type="email"
        value={email || ''}
        onChange={(e) => onChange({ email: e.target.value })}
      />
      <Button onClick={onSubmit}>Send recovery</Button>
    </Stack>
  )
}
Form.propTypes = {
  email: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
}
