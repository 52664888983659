import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { Form } from './Component'
import { SEND_RECOVERY } from '../../queries'

export const RecoveryForm = () => {
  const [email, setEmail] = useState()
  const [sendRecovery] = useMutation(SEND_RECOVERY)

  const onSubmit = () => sendRecovery({ variables: { email } })
  const onChange = ({ email }) => setEmail(email)

  return <Form email={email} onChange={onChange} onSubmit={onSubmit} />
}
