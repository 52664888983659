import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { handleFieldChange } from './helpers'

export const NoteField = ({ note, onChange, ...props }) => (
  <TextField
    multiline
    name="note"
    label="Note"
    rows={3}
    value={note || ''}
    onChange={handleFieldChange(onChange)}
    {...props}
  />
)
NoteField.propTypes = {
  note: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
