import { useContext } from 'react'
import { useRouter } from 'next/router'
import { User } from './Providers/Contexts'
import { LoginScreen } from './Login'

const RequireLogin = ({ children, excludePaths }) => {
  const { user, loading, error } = useContext(User)
  const { pathname } = useRouter()

  if (excludePaths?.includes(pathname)) return children

  if (!user && !loading) return <LoginScreen />

  if (!user && loading) return <div>logging-in</div>

  if (error) return error.message

  if (user) return children
}

export default RequireLogin
