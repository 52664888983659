export const handleFieldChange =
  (onChange) =>
  ({ target: { name, value } }) =>
    onChange({ [name]: value })

export const handleIntFieldChange =
  (onChange) =>
  ({ target: { name, value } }) =>
    onChange({ [name]: parseInt(value) })

export const onChange = (...props) => {
  console.debug('onChange', { props })
}
