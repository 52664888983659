import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useQuery } from '@apollo/client'
import { GET_FARMS } from '../queries'
import { FarmDropDownComponent } from './Component'

export function FarmDropDown({
  disabled = false,
  defaultValue = -1,
  onSelect,
  value = -1,
  margin = 'dense',
  size = 'small',
  fullWidth = true,
  required = false,
  noFarmLabel = 'None',
  allowEmpty = true,
  label = 'Farm',
}) {
  const { data, loading } = useQuery(GET_FARMS)

  const farms = useMemo(() => {
    if (!loading) {
      return data?.getFarms || []
    } else {
      return []
    }
  }, [loading, data])

  const onChange = (e) => {
    const farmData = farms.find((pkt) => pkt.id === e.target.value)
    onSelect(e.target.value, farmData)
  }

  const displayValue = useMemo(() => {
    if (typeof value === 'number') {
      return value
    } else if (value?.id) {
      return parseInt(value.id)
    } else {
      return defaultValue
    }
  }, [defaultValue, value])

  return (
    <FarmDropDownComponent
      label={label}
      displayValue={displayValue}
      onChange={onChange}
      margin={margin}
      size={size}
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      allowEmpty={allowEmpty}
      noFarmLabel={noFarmLabel}
      farms={farms}
    />
  )
}

FarmDropDown.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func.isRequired,
  margin: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  noFarmLabel: PropTypes.string,
  label: PropTypes.string,
  allowEmpty: PropTypes.bool,
}
