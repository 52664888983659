import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { MenuItem, TextField } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 120,
  },
}))

export function FarmDropDownComponent({
  allowEmpty,
  disabled,
  displayValue,
  farms,
  fullWidth,
  label,
  margin,
  noFarmLabel,
  onChange,
  required,
  size,
}) {
  const classes = useStyles()

  return (
    <TextField
      select
      label={label}
      value={displayValue}
      onChange={onChange}
      variant="outlined"
      margin={margin}
      required={required}
      size={size}
      disabled={disabled}
      className={classes.select}
      fullWidth={fullWidth}
    >
      {allowEmpty && (
        <MenuItem key="-1" value={-1}>
          {noFarmLabel}
        </MenuItem>
      )}
      {farms.map((farm) => (
        <MenuItem key={farm.id} value={farm.id}>
          {farm.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

FarmDropDownComponent.propTypes = {
  allowEmpty: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  displayValue: PropTypes.number.isRequired,
  farms: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired,
  noFarmLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
}
