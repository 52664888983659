import { TextField } from '@material-ui/core'
import { DatePicker, TimePicker } from '@material-ui/lab'
import { getHours, getMinutes, set } from 'date-fns'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'

export const DeliverOnDateField = ({ deliverOn, onChange, ...props }) => (
  <DatePicker
    name="deliverOn"
    value={deliverOn}
    inputFormat="P' | Week 'I"
    onChange={(deliverOn) => onChange({ deliverOn })}
    renderInput={(props) => <TextField {...props} sx={{ width: 0.7 }} />}
    {...props}
  />
)

export const DeliverOnTimeField = ({ deliverOn, onChange, ...props }) => (
  <TimePicker
    ampm={false}
    autoOk
    label="Delivery time"
    name="deliverOn"
    value={deliverOn}
    onChange={(deliverOn) => onChange({ deliverOn })}
    renderInput={(props) => <TextField {...props} sx={{ width: 0.3 }} />}
    {...props}
  />
)
const propTypes = {
  deliverOn: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
}

DeliverOnDateField.propTypes = propTypes
DeliverOnTimeField.propTypes = propTypes

const getDeliveryTime = ({ customer, fallbackTime = '09:00' }) => {
  const [hours, minutes] = (customer?.deliveryTime || fallbackTime)?.split(':')
  return { hours, minutes, seconds: '00' }
}

export const useDeliverOn = () => {
  const [deliverOn, setDeliverOn] = useState()

  const onDeliverOnDateChange =
    ({ customer }) =>
    ({ deliverOn: deliverOnDate }) =>
      setDeliverOn(
        set(
          deliverOnDate,
          deliverOn
            ? {
                hours: getHours(deliverOn),
                minutes: getMinutes(deliverOn),
              }
            : getDeliveryTime({ customer }),
        ),
      )

  const onDeliverOnChange = ({ deliverOn }) => setDeliverOn(deliverOn)

  return {
    deliverOn,
    onDeliverOnChange,
    onDeliverOnDateChange,
  }
}

export const useDefaultDeliverOn = ({ customer, dateOn }) =>
  useMemo(() => set(dateOn, getDeliveryTime({ customer })), [customer, dateOn])
