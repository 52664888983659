import { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Menu,
  ListItem,
  Button,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { FarmAvatar } from '../FarmAvatar'
import LoadingSpinner from '../../LoadingSpinner'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  buttonText: {
    color: theme.palette.text.primary,
    textTransform: 'none',
  },
  listItemAvatar: {
    minWidth: '0',
  },
}))

export function FarmPickerComponent({
  openDropdown,
  handleClick,
  handleClose,
  handleFarmClick,
  wide,
  farm,
  anchorEl,
  farms,
  loading = true,
}) {
  const classes = useStyles()

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Fragment>
      <Button
        id="basic-menu"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={openDropdown ? 'true' : undefined}
        onClick={handleClick}
        variant="text"
      >
        {!loading ? (
          <Fragment>
            <FarmAvatar farm={farm} />
            {wide && (
              <Typography ml={1} className={classes.buttonText}>
                {farm?.name || 'No farm'}
              </Typography>
            )}
          </Fragment>
        ) : wide ? (
          'Loading...'
        ) : (
          ''
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openDropdown}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {farms.map((farm) => (
          <ListItem
            button
            key={farm.id}
            onClick={(e) => handleFarmClick(e, farm)}
          >
            <ListItemAvatar className={classes.listItemAvatar}>
              <Box mr={1}>
                <FarmAvatar farm={farm} />
              </Box>
            </ListItemAvatar>
            <ListItemText>{farm.name}</ListItemText>
          </ListItem>
        ))}
      </Menu>
    </Fragment>
  )
}

FarmPickerComponent.propTypes = {
  openDropdown: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleFarmClick: PropTypes.func.isRequired,
  wide: PropTypes.bool.isRequired,
  farm: PropTypes.object,
  anchorEl: PropTypes.object,
  farms: PropTypes.array.isRequired,
  loading: PropTypes.bool,
}
