import { Visibility, VisibilityOff } from '@material-ui/icons'
import {
  Button,
  InputAdornment,
  IconButton,
  Stack,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'

export const Form = ({ credentials, onChange, onSubmit }) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Stack direction="column" spacing={2}>
      <TextField
        label="Email"
        type="email"
        value={credentials?.email || ''}
        onChange={(e) => onChange({ email: e.target.value })}
      />
      <TextField
        label="Password"
        value={credentials?.password || ''}
        onChange={(e) => onChange({ password: e.target.value })}
        type={showPassword ? 'text' : 'password'}
        autoComplete="current-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        onClick={onSubmit}
        size="large"
        color="primary"
        variant="outlined"
      >
        Login
      </Button>
    </Stack>
  )
}

Form.propTypes = {
  credentials: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
