export const quantityByPackageType = ({ packageType, packageQuantity }) =>
  packageType?.productWeight * packageQuantity

export const quantityByTrayType = ({ product, trayType, trayQuantity }) =>
  product?.yielding * trayType?.area * trayQuantity

export const packageQuantityFromQuantity = ({
  packageType,
  product,
  quantity,
  trayType,
}) =>
  Math.floor(
    quantity /
      (packageType?.productWeight ||
        quantityByTrayType({ product, trayType, trayQuantity: 1 }) *
          packageType?.trayQuantity),
  ) || 0

export const trayQuantityFromQuantity = ({ trayType, product, quantity }) =>
  Math.floor(quantity / (product?.yielding * trayType?.area)) || 0

export const quantityByContainer = ({
  product,
  packageType,
  packageQuantity,
  trayType,
  trayQuantity,
}) => {
  const byPackageWeight =
    packageType && packageQuantity && packageType.productWeight
  const byPackageCount =
    packageType && packageQuantity && packageType.trayQuantity
  const byTrayCount = trayType && trayQuantity

  return (
    (byPackageWeight &&
      quantityByPackageType({ packageType, packageQuantity })) ||
    (byPackageCount &&
      packageQuantity *
        quantityByTrayType({
          product,
          trayType,
          trayQuantity: packageType.trayQuantity,
        })) ||
    (byTrayCount && quantityByTrayType({ product, trayType, trayQuantity }))
  )
}

export const calculateTotalPrice = ({
  deliveredPackageQuantity = 0,
  deliverTrayQuantity = 0,
  deliveredQuantity = 0,
  packageQuantity = 0,
  trayQuantity = 0,
  quantity = 0,
  unitPrice = 0,
}) => {
  const qty =
    deliveredPackageQuantity ||
    deliverTrayQuantity ||
    deliveredQuantity ||
    trayQuantity ||
    packageQuantity ||
    quantity
  const totalPrice = unitPrice * qty

  return totalPrice
}
