import { InputAdornment, TextField } from '@material-ui/core'
import { toLocaleMainUnits, toMainUnits, toSubUnits } from 'helpers'
import { calculateTotalPrice } from 'helpers/order-calculations'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { handleFieldChange } from './helpers'

export const PriceField = ({ price, currency, symbol, onChange, ...props }) => (
  <TextField
    label="Price"
    type="number"
    name="price"
    value={price || ''}
    onChange={handleFieldChange(onChange)}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {currency?.symbol || symbol}
        </InputAdornment>
      ),
    }}
    inputProps={{ min: 0 }}
    {...props}
  />
)
PriceField.propTypes = {
  price: PropTypes.object,
  currency: PropTypes.object,
  symbol: PropTypes.string,
  onChange: PropTypes.func,
}

export const usePrice = ({ currency }) => {
  const [priceInUnits, setPriceInUnits] = useState()
  const [priceInSubunits, setPriceInSubunits] = useState()

  const onPriceInUnitsChange = ({ price } = {}) => {
    if (Number.isFinite(Number(price))) {
      setPriceInUnits(price)
      setPriceInSubunits(toSubUnits(price, currency))
    } else {
      setPriceInUnits()
      setPriceInSubunits()
    }
  }

  const onPriceInSubunitsChange = ({ price } = {}) => {
    setPriceInSubunits(price)
    setPriceInUnits(toMainUnits(price, currency))
  }

  return {
    onPriceInSubunitsChange,
    onPriceInUnitsChange,
    priceInSubunits,
    priceInUnits,
  }
}

export const useTotalPrice = ({
  currency,
  packageQuantity,
  quantity,
  trayQuantity,
  unitPrice,
}) =>
  useMemo(() => {
    const price = calculateTotalPrice({
      packageQuantity,
      trayQuantity,
      quantity,
      unitPrice,
    })

    const totalPrice = toMainUnits(price, currency)

    return { totalPrice }
  }, [currency, unitPrice, quantity, trayQuantity, packageQuantity])

export const usePriceInUnits = (price, currency) =>
  useMemo(() => toMainUnits(price, currency), [price, currency])

export const useLocalePriceInUnits = (price, currency) =>
  useMemo(() => toLocaleMainUnits(price, currency), [price, currency])
