import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@material-ui/core'
import { handleFieldChange, handleIntFieldChange } from 'components/Inputs'
import DialogSpinner from 'components/DialogSpinner'
import PropTypes from 'prop-types'
import { useDialog } from 'hooks'
import {
  CurrencyCodeDropDown,
  useCurrency,
  useCreateCurrency,
  useEditCurrency,
  useDeleteCurrency,
} from './'
import { ConfirmationDialog, useConfirmation } from 'components/ConfirmDialog'

export const CurrencyDialog = ({
  currency,
  isEditing,
  isSubmitting,
  onChange,
  onClose,
  onSubmit,
  open,
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm">
    <DialogSpinner spin={isSubmitting} />
    <DialogTitle>{isEditing ? 'Edit Currency' : 'Create Currency'}</DialogTitle>

    <DialogContent dividers>
      <Stack direction="column" spacing={2}>
        <CurrencyCodeDropDown
          currencyCode={currency?.code}
          onChange={onChange}
        />

        <TextField
          label="Symbol"
          name="symbol"
          onChange={handleFieldChange(onChange)}
          value={currency?.symbol || ''}
        />

        <TextField
          label="Divisor"
          name="divisor"
          onChange={handleIntFieldChange(onChange)}
          value={currency?.divisor || ''}
        />
      </Stack>
    </DialogContent>

    <DialogActions>
      <Button onClick={onClose}>Close</Button>
      <Button onClick={onSubmit}>Save</Button>
    </DialogActions>
  </Dialog>
)
CurrencyDialog.propTypes = {
  currency: PropTypes.object,
  isEditing: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export const useCurrencyDialog = ({ onError }) => {
  const { currency, onCurrencyChange } = useCurrency()

  const { open, onOpen, onClose } = useDialog({
    onOpen: onCurrencyChange,
    onClosed: onCurrencyChange,
  })

  const { onCreate, loading: submittingNew } = useCreateCurrency({
    onCompleted: onClose,
    onError,
  })
  const { onEdit, loading: submittingEdit } = useEditCurrency({
    currency,
    onCompleted: onClose,
    onError,
  })

  const isSubmitting = submittingNew || submittingEdit
  const isEditing = !!currency?.id

  const onChange = (props) =>
    onCurrencyChange({ currency: { ...currency, ...props } })

  const onSubmit = () => (isEditing ? onEdit : onCreate)(currency)

  return {
    currency,
    isEditing,
    isSubmitting,
    onChange,
    onClose,
    onOpen,
    onSubmit,
    open,
  }
}

export const CurrencyDeleteDialog = ({ currency, ...props }) => (
  <ConfirmationDialog {...props}>
    Are you sure you want delete {currency?.code}?
  </ConfirmationDialog>
)

export const useDeleteDialog = ({ onError }) => {
  const { onDelete } = useDeleteCurrency({ onError })
  const { onConfirm: onConfirmDelete, ...props } = useConfirmation({
    onConfirmation: onDelete,
  })

  return { onConfirmDelete, ...props }
}

CurrencyDeleteDialog.propTypes = {
  currency: PropTypes.object,
}
