import PropTypes from 'prop-types'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'

export const cache = createCache({ key: 'css', prepend: true })

export const StylesCache = ({ children }) => {
  return <CacheProvider value={cache}>{children}</CacheProvider>
}

StylesCache.propTypes = {
  children: PropTypes.node.isRequired,
}
